import classNames from './styles.module.scss'


export default function DotsList(dots) {
  return (
    <div>
      <ul className={classNames.dotList}>
        {dots}
      </ul>
    </div>
  )
}
