import PropTypes from 'prop-types'

import { Chart } from 'common/widgets'
import generateColorsForChart from './generateColorsForChart'
import generateDataForChart from './generateDataForChart'
import { useTranslations } from 'common/language'


SinglePageNationalityView.propTypes = {
  data: PropTypes.array.isRequired,
}

export default function SinglePageNationalityView({ data, ...props }) {
  const { gettext } = useTranslations()
  const columns = generateDataForChart(data, 'Other')
  const colors = generateColorsForChart(data)
  return (
    <Chart
      {...props}
      columns={columns}
      colors={colors}
      title={gettext('Nationality Mix')}
    />
  )
}
