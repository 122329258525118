import { useMemo } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import Image from 'next/image'

import { BlockWrapper, Typography, GradientBlock } from 'common/widgets'
import { ImageType } from '../types'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import classNames from './styles.module.scss'


HeroWithText.propTypes = {
  data: PropTypes.shape({
    quote_blocks: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      image: ImageType,
    })),
  }).isRequired,
}

export default function HeroWithText({ data }) {
  const blocks = useMemo(() => data.quote_blocks.map(({ heading, text, image }, index) => {
    return (
      <BlockWrapper key={index}>
        {heading && (
          <div className={classNames.hero}>
            <Image
              src={getImgFromBynder(get(image, 'bynder'), ['header', 'webbig', 'webImage'])}
              alt={get(image, 'data.alttext')}
              title={get(image, 'data.name')}
              layout="fill"
              objectFit="cover"
              priority
            />
            <GradientBlock />
            <div className={classNames.headingText}>
              <Typography variant="aboutHero" color="white" transform="uppercase">
                {heading}
              </Typography>
            </div>
          </div>
        )}
        {text && (
          <div className={classNames.text}>
            <Typography variant="content" color="black">
              {text}
            </Typography>
          </div>
        )}
      </BlockWrapper>
    )
  }), [data.quote_blocks])
  return blocks
}
