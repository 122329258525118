import { useMemo } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { Container } from 'common/widgets'
import Arrow from './Arrow'
import ImageSlide from './ImageSlide'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


TwoColumnCarousel.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.arrayOf(ImageType),
  }),
  priorityLoad: PropTypes.bool,
  lastBlock: PropTypes.bool,
}

TwoColumnCarousel.defaultProps = {
  data: {
    images: [],
  },
  priorityLoad: false,
  lastBlock: false,
}

const settings = {
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  nextArrow: <Arrow position="right" />,
  prevArrow: <Arrow position="left" />,
}

export default function TwoColumnCarousel({ data, priorityLoad, lastBlock }) {
  const slides = useMemo(() => data.images.map((img, index) => (
    <ImageSlide
      key={index}
      image={img}
      priority={priorityLoad && index < 2}
    />
  )), [data.images])

  return (
    <Container size="xl" className={lastBlock ? classNames.lastBlock : ''}>
      <Slider {...settings} className={classNames.slider}>
        {slides}
      </Slider>
    </Container>
  )
}
