import trim from 'lodash/trim'

// TODO: find solution without passing 'data' argument to function
export default function parseTemplateData(template, data = {}) {
  const templateString = template || ''
  return templateString.replace(/<%=([\s\S]+?)%>/g, function(_, code) {
    var v = trim(code.replace(/\\'/g, '\''))
    if(data[v]) {
      return `<%= ${v} %>`
    }
    return ''
  })
}
