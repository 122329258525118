import PropTypes from 'prop-types'


EmptyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

// created to evade warnings in console when passing props to Fragment
export default function EmptyWrapper({ children }) {
  return children
}
