import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import get from 'lodash/get'
import RMasonry from 'react-masonry-css'

import { Container, BlockWrapper, Button, Typography } from 'common/widgets'
import { useTranslations } from 'common/language'
import ExperienceMasonryItem from './ExperienceMasonryItem'
import classNames from './styles.module.scss'


ExperienceMasonry.propTypes = {
  data: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    parent_id: PropTypes.string.isRequired,
  }).isRequired,
  getExperiences: ResourceType.isRequired,
  withoutCTA: PropTypes.bool,
}

ExperienceMasonry.defaultProps = {
  withoutCTA: false,
}

const breakpointColumnsObj = {
  default: 3,
  1024: 2,
  576: 1,
}

export default function ExperienceMasonry({ data, getExperiences, withoutCTA }) {
  const { gettext } = useTranslations()
  const isMoreEnabled = useMemo(() => get(getExperiences, 'data.results', []).length < get(getExperiences, 'data.count'), [getExperiences?.data])
  const experiences = useMemo(() => get(getExperiences, 'data.results', []), [getExperiences?.data])
  const items = useMemo(
    () =>
      experiences.map((option, index) => (
        <ExperienceMasonryItem
          key={index}
          cta={gettext('View Language School')}
          {...option}
          school_url={withoutCTA ? null : option.school_url}
        />
      )),
    [experiences]
  )
  const getAdditionalExperiences = useCallback(() => {
    getExperiences.fetch({ kind: data.kind, parent_id: data.parent_id, offset: (getExperiences?.filters?.offset || 0) + 3, limit: 3 })
  }, [getExperiences?.filters?.offset, data.kind, data.parent_id])
  return (
    <BlockWrapper>
      <Container size="xl">
        {getExperiences?.data?.count !== 0
          ? (<RMasonry
            breakpointCols={breakpointColumnsObj}
            className={classNames.container}
            columnClassName={classNames.column}
          >
            {items}
          </RMasonry>)
          : (
            <Typography
              variant="h4"
              align="center"
              className={classNames.emptyText}
            >
              {gettext('There is no snapshot available yet.')}
            </Typography>
          )}
        {isMoreEnabled && (
          <div className={classNames.showMore}>
            <Button
              onClick={getAdditionalExperiences}
              className={classNames.button}
              isLoading={getExperiences?.isLoading}
            >
              {gettext('more')}
            </Button>
          </div>)}
      </Container>
    </BlockWrapper>
  )
}
