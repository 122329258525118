import { useMemo, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import NavLink from 'common/router/Link'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import cx from 'classnames'

import { Container, Button } from 'common/widgets'
import Masonry from 'react-masonry-css'
import EmptyWrapper from './EmptyWrapper'
import classNames from './styles.module.scss'
import { useRouter } from 'next/router'


const breakpointCols = { default: 5, 1400: 4, 1280: 3, 1024: 1 }

NavItem.propTypes = {
  setActiveCards: PropTypes.func.isRequired,
  handleActiveCard: PropTypes.func.isRequired,
  activeCards: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isActiveCart: PropTypes.bool,
  isFirstLevel: PropTypes.bool,
  isNative: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

NavItem.defaultProps = {
  isActiveCart: false,
  isFirstLevel: false,
  isNative: false,
  onClick: undefined,
  className: undefined,
}

export default function NavItem({ data, activeCards, setActiveCards, isActiveCart, isFirstLevel, handleActiveCard, onClick, className, isNative }) {
  const { locale, locales } = useRouter()
  const Link = useMemo(() => {
    if(data.url && data.url !== '#' && !isNative) {
      let href = data.url
      if(!isObject(data.url) && locales.includes(data.url.split('/')[1])) {
        href = { pathname: '/' + data.url.split('/').slice(2).join('/') }
      }

      return (props) => (
        <NavLink
          passHref
          prefetch={false}
          href={href}
          activeClassName={classNames.bold}
          locale={['default', 'en'].includes(locale) ? 'de' : locale}
          {...props}
        />
      )
    }
    return (props) => <Fragment {...props} />
  }, [data, isNative])
  const Tag = useMemo(() => {
    if(data.url && data.url !== '#') {
      return 'a'
    }
    return 'span'
  }, [data.url])
  const Wrapper = useMemo(() => isFirstLevel ? Masonry : EmptyWrapper, [isFirstLevel])
  const ContainerWrapper = useMemo(() => isFirstLevel ? Container : EmptyWrapper, [isFirstLevel])
  const handleActive = useCallback(_ => handleActiveCard(data.title), [data.title, handleActiveCard])
  const subNavs = useMemo(() => !isEmpty(data.subsections) ? data.subsections.map((subSection, index) => (
    <NavItem
      key={index}
      isNative={isNative}
      data={subSection}
      activeCards={activeCards}
      setActiveCards={setActiveCards}
      isActiveCart={isActiveCart}
      tealLinks={subSection.is_primary_color}
      handleActiveCard={handleActiveCard}
    />
  )) : null, [data.subsections, isNative, activeCards, setActiveCards, isActiveCart, isFirstLevel, handleActiveCard])
  return (
    <Fragment>
      <span
        className={cx(classNames.linkWrapper, className)}
        onClick={onClick}
      >
        <Link {...get(data, 'linkProps', {})}>
          <Tag
            target={data.is_open_in_new_tab ? '_blank' : undefined}
            rel={data.is_nofollow ? 'nofollow' : undefined}
            className={cx(data.is_primary_color && classNames.teal, classNames.link)}
            href={Tag !== 'span' && isNative ? data.url : undefined}
          >
            {data.title}
          </Tag>
        </Link>
        {!isEmpty(data.subsections) && (
          <Button
            spin={false}
            type="link"
            icon="sort-down"
            className={classNames.subIcon}
            onClick={handleActive}
            aria-label={data.title}
          />
        )}
      </span>
      {!isEmpty(data.subsections) && (
        <div className={cx(classNames.subSection, activeCards[data.title] && classNames.visible, !isActiveCart && classNames.hidden)} data-qa="submenu">
          <span className={cx(classNames.linkWrapper, classNames.backButton)}>
            <Button
              spin={false}
              type="link"
              icon="arrow-left"
              onClick={handleActive}
            />
            <span>{data.title}</span>
          </span>
          <ContainerWrapper size="xl" className={classNames.navContainer}>
            <Wrapper
              breakpointCols={breakpointCols}
              className={classNames.masonryGrid}
              columnClassName={classNames.masonryColumn}
            >
              {subNavs}
            </Wrapper>
          </ContainerWrapper>
        </div>
      )}
    </Fragment>
  )
}
