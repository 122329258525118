import PropTypes from 'prop-types'

import { Container, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'


Content.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.string,
  }),
}

Content.defaultProps = {
  data: {},
}

export default function Content({ data }) {
  return (
    <BlockWrapper>
      <Container size="xl">
        <div className={classNames.content} dangerouslySetInnerHTML={{ __html: data.body }} />
      </Container>
    </BlockWrapper>
  )
}
