import { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Col, Row } from 'antd'
import Image from 'next/image'

import withScroll from 'common/utils/withScroll'
import { Container, CTALink, Typography, GradientBlock } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


const propTypes = {
  data: PropTypes.shape({
    image: ImageType,
    heading: PropTypes.string,
    text: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.shape({
      cta_link_caption: PropTypes.string,
      cta_link: PropTypes.string,
      cta_link_type: PropTypes.string,
    })),
  }),
}

const defaultProps = {
  data: {},
}

const HeroImageAnimated = forwardRef(({ data }, ref) => {
  const ctas = useMemo(() => data.buttons.map(({ cta_link_caption: ctaLinkCaption, cta_link: ctaLink, cta_link_type: ctaLinkType }) => (
    <div key={ctaLinkCaption} className={classNames.ctaContainer}>
      <CTALink
        cta={ctaLinkCaption}
        ctaURL={ctaLink}
        className={classNames.cta}
        type={ctaLinkType}
      />
    </div>
  )), [data.buttons])

  return (
    <div className={classNames.wrapper}>
      <Image
        src={getImgFromBynder(get(data, 'image.bynder'), ['header', 'webbig', 'webImage'])}
        alt={get(data, 'image.data.alttext')}
        title={get(data, 'image.data.name')}
        layout="fill"
        objectFit="cover"
        priority
      />
      <GradientBlock />
      <Container size="xl">
        <Row className={classNames.container} ref={ref}>
          <Col span={24} lg={12} xl={10}>
            <Typography variant="h2" color="white">{data.heading}</Typography>
            <Typography color="white" className={classNames.text}>{data.text}</Typography>
            <div className={classNames.ctas}>
              {ctas}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})

HeroImageAnimated.propTypes = propTypes
HeroImageAnimated.defaultProps = defaultProps

export default withScroll(HeroImageAnimated, classNames.active)
