import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from 'common/widgets'
import Name from './Name'
import Title from './Title'


CustomTypography.propTypes = {
  variant: PropTypes.oneOf(['name', 'title']),
  className: PropTypes.string,
  children: PropTypes.node,
}

CustomTypography.defaultProps = {
  variant: undefined,
  className: undefined,
  children: undefined,
}

export default function CustomTypography({ variant, className, children }) {
  const Component = useMemo(() => {
    switch (variant) {
    case 'name':
      return Name
    case 'title':
      return Title
    default:
      return Typography
    }
  }, [variant])

  return <Component className={className}>{children}</Component>
}
