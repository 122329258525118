import PropTypes from 'prop-types'
import get from 'lodash/get'
import Image from 'next/image'

import { Container, SchoolFilters, GradientBlock } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


HeroImageFilter.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    button_caption: PropTypes.string,
    heading_font_size: PropTypes.oneOf(['small', 'normal', 'large']),
    image: ImageType.isRequired,
  }),
}

HeroImageFilter.defaultProps = {
  data: {},
}

export default function HeroImageFilter({ data: { heading, heading_font_size: headingFontSize, button_caption: btnCaption, image }, ...rest }) {
  return (
    <div className={classNames.wrapper}>
      <Image
        src={getImgFromBynder(get(image, 'bynder'), ['header', 'webbig', 'webImage'])}
        alt={get(image, 'data.alttext')}
        title={get(image, 'data.name')}
        className={classNames.image}
        layout="fill"
        objectFit="cover"
        priority
      />
      <GradientBlock />
      <Container size="xl">
        <div className={classNames.filters}>
          <SchoolFilters
            heading={heading}
            headingFontSize={headingFontSize}
            btnCaption={btnCaption}
          />
        </div>
      </Container>
    </div>
  )
}
