import { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Row, Col } from 'antd'

import { Container, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'


RotatingText.propTypes = {
  data: PropTypes.shape({
    left_side_text_static_part: PropTypes.string,
    left_side_text_animated_part: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string,
    })),
    right_side_text: PropTypes.string,
  }),
}

RotatingText.defaultProps = {
  data: {},
}

export default function RotatingText({ data }) {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const words = useRef({})

  const newText = useMemo(() => data.left_side_text_static_part.split('<animated>'), [data.left_side_text_static_part])
  const options = useMemo(() => {
    return data.left_side_text_animated_part.map(({ text, color }, optionIndex) => {
      const newWordIndex = currentIndex === data.left_side_text_animated_part.length - 1 ? 0 : currentIndex + 1
      const nextWordIndex = newWordIndex === data.left_side_text_animated_part.length - 1 ? 0 : newWordIndex + 1
      return (

        <span key={text} ref={ref => (words.current[text] = ref)} className={classNames.word} style={{ color }}>
          {text.split('').map((symbol, index) => (
            <span
              key={index}
              className={cx(
                classNames.letter,
                newWordIndex === optionIndex
                  ? classNames.in
                  : nextWordIndex === optionIndex ? classNames.behind : classNames.out,
              )}
              style={currentIndex === optionIndex
                ? { transitionDelay: `${index * 0.08}s` }
                : newWordIndex === optionIndex
                  ? { transitionDelay: `${0.34 + index * 0.08}s` }
                  : undefined
              }
            >
              {symbol}
            </span>
          ))}
        </span>
      )
    })
  }, [data.left_side_text_animated_part, currentIndex])


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentIndex(currentIndex === data.left_side_text_animated_part.length - 1 ? 0 : currentIndex + 1)
    }, 4000)

    return () => clearTimeout(timeoutId)
  }, [setCurrentIndex, currentIndex])

  const longestWord = useMemo(_ => {
    return data.left_side_text_animated_part.reduce((string, obj) => {
      if(string.length < obj.text.length) {
        return obj.text
      }
      return string
    }, '')
  }, [data.left_side_text_animated_part])

  return (
    <BlockWrapper>
      <Container size="xl" className={classNames.wrapper}>
        <Row gutter={[15, 0]}>
          <Col span={24} md={data.right_side_text ? 12 : 24}>
            <Row justify="center">
              <Typography className={classNames.text} tag="span">
                <h2 className={classNames.marginRight}>
                  {newText[0]}
                </h2>
                <Typography className={cx(classNames.text, classNames.rotatedText)} tag="span">
                  {options}
                  <span className={classNames.legend}>
                    {longestWord}
                  </span>
                </Typography>
              </Typography>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <Typography>{data.right_side_text}</Typography>
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
