import PropTypes from 'prop-types'

import { Container } from 'common/widgets'


Quote.propTypes = {
  data: PropTypes.shape({
    quote: PropTypes.string,
    signature: PropTypes.string,
    author: PropTypes.string,
  }),
}

Quote.defaultProps = {
  data: {},
}

// It is used only for test purpose for client and will be removed in future
export default function Quote({ data }) {
  return (
    <Container size="xl">
      <blockquote>{data.quote}</blockquote>
      <p>{data.author}</p>
      <p>{data.signature}</p>
    </Container>
  )
}
