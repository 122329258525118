import { Component } from 'react'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'
import isEmpty from 'lodash/isEmpty'

import FormsView from './FormsView'
import API from 'api'


const propTypes = {
  data: PropTypes.shape({
    form_kind: PropTypes.string,
    fields: PropTypes.object,
  }),
  crmForms: ResourceType.isRequired,
}

const defaultProps = {
  data: {},
}

export default class FormsContainer extends Component {
  state = {
    formData: this.props?.data?.fields || {},
  }
  componentDidMount() {
    if(isEmpty(this.state.formData)) {
      API.get(`api/v1/crm/forms/${this.props.data.form_kind || 'contact-form'}`)
        .then(formData => this.setState({ formData }))
    }
  }

  render() {
    return (
      <FormsView {...this.props} {...this.state} isLoading={this.props.crmForms.isLoading} />
    )
  }
}

FormsContainer.propTypes = propTypes
FormsContainer.defaultProps = defaultProps
