import { useMemo, useState, useCallback } from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/get'

import { CTALink, Typography } from 'common/widgets'
import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


ExperienceMasonryItem.propTypes = {
  author_display_location: PropTypes.string.isRequired,
  author_display_name: PropTypes.string,
  experience_review: PropTypes.string.isRequired,
  return_date: PropTypes.string.isRequired,
  school_name: PropTypes.string.isRequired,
  school_url: PropTypes.string,
  cta: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      orientation: PropTypes.oneOf(['p', 'l']),
      url: PropTypes.string,
      alt_text: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
}

ExperienceMasonryItem.defaultProps = {
  author_display_name: '',
  school_url: null,
}

export default function ExperienceMasonryItem({
  photos,
  return_date: returnDate,
  experience_review: experienceReview,
  author_display_name: authorName,
  school_name: schoolName,
  school_url: schoolUrl,
  cta,
}) {
  const { gettext } = useTranslations()
  const imageUrl = useMemo(() => get(photos, '[0].url'), [photos])
  const imageAlt = useMemo(() => get(photos, '[0].alt_text'), [photos])
  const imageTitle = useMemo(() => get(photos, '[0].title'), [photos])
  const imageOrientation = useMemo(
    () => get(photos, '[0].orientation'),
    [photos]
  )
  const quotedReview = experienceReview ? '«' + experienceReview + '»' : ''
  const isCollapsedDefault = quotedReview.length > 300
  const [isCollapsed, setCollapsed] = useState(isCollapsedDefault)
  const dynamicReview = isCollapsed ? quotedReview.substring(0, 300) : quotedReview
  const toggleCollapse = useCallback(() => setCollapsed(isCollapsed => !isCollapsed), [])
  return (
    <div className={classNames.item}>
      {imageUrl && (<div
        className={cx(
          classNames.imageContainer,
          imageOrientation === 'p' ? classNames.portrait : classNames.landscape
        )}
      >
        <Image
          src={imageUrl}
          alt={imageAlt}
          title={imageTitle}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          sizes="(max-width: 576px) 100vw, (max-width: 1024px) 50vw, 33vw"
        />
      </div>
      )}
      <div className={classNames.info}>
        <Typography
          variant="content"
          className={cx(classNames.infoText, classNames.heading)}
        >
          {schoolName}
        </Typography>
        <Typography
          variant="content"
          className={cx(classNames.text, classNames.infoText)}
        >
          {dynamicReview}
          {dynamicReview && isCollapsedDefault && (
            <button
              type="button"
              className={classNames.toggleReview}
              onClick={toggleCollapse}
            >
              {'...'}{isCollapsed ? gettext('Learn more') : gettext('Hide')}
            </button>)}
        </Typography>
        <Typography
          variant="content"
          className={cx(classNames.infoText, classNames.tagBlock)}
        >
          {authorName || gettext('Anonymous')}
        </Typography>
        <Typography
          variant="content"
          className={cx(classNames.infoText, classNames.tagBlock)}
        >
          {returnDate}
        </Typography>
        <CTALink
          cta={cta}
          ctaURL={schoolUrl}
          isUnderline
          className={cx(classNames.cta, classNames.infoText)}
        >
          {cta}
        </CTALink>
      </div>
    </div>
  )
}
