import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { Container, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'


SplitTextCard.propTypes = {
  data: PropTypes.shape({
    left_side_text: PropTypes.string,
    right_side_text: PropTypes.string,
  }),
}

SplitTextCard.defaultProps = {
  data: {},
}

export default function SplitTextCard({ data }) {
  return (
    <BlockWrapper>
      <Container size="xl">
        <Row>
          <Col span={24} md={12}>
            <Typography
              className={classNames.subheading}
              variant="h4"
              dangerouslySetInnerHTML={{
                __html: data.left_side_text,
              }}
            />
          </Col>
          <Col span={24} md={12} className={classNames.right}>
            <Typography>{data.right_side_text}</Typography>
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
