import { Row, Col } from 'antd'
import { useState, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import Image from 'next/image'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import classNames from './styles.module.scss'
import { ImageType } from '../../../types'
import getImgFromBynder from 'common/utils/getImgFromBynder'


const Lightbox = dynamic(() => import('./Lightbox'), { ssr: false })

ImageGridView.propTypes = {
  images: PropTypes.arrayOf(ImageType).isRequired,
}

export default function ImageGridView({ images }) {
  const { locale } = useRouter()
  const [isOpenIndex, handleOpen] = useState(null)
  const parsedImages = useMemo(() => images.map(i => ({
    url: getImgFromBynder(get(i, 'bynder'), ['webImage']),
    modal: getImgFromBynder(get(i, 'bynder'), ['webbig', 'webImage']),
    alt: get(i, 'data.alttext', get(i, `data.alttext_${locale}`)),
    title: get(i, 'data.name', get(i, `data.name_${locale}`)),
    caption: get(i, 'data.caption', get(i, `data.caption_${locale}`)),
  })), [images])
  const firstImage = useMemo(() => get(parsedImages, '[0]', {}), [parsedImages])
  const imageCols = useMemo(() => (
    <Row className={classNames.imageRow}>
      <div
        className={classNames.leftImage}
        onClick={_ => handleOpen(0)}
      >
        <Image
          src={firstImage.url}
          alt={firstImage.alt}
          title={firstImage.title}
          onClick={_ => handleOpen(0)}
          sizes="(max-width: 575px) 54vw, (max-width: 1024px) 42vw, (max-width: 1440px) 40vw, 615px"
          layout="fill"
          objectFit="cover"
          priority
        />
      </div>
      {parsedImages.length > 1 && (
        <div>
          <Row className={cx(classNames.rightRow, classNames.bigSize)}>
            <Col span={24} sm={16} lg={24} className={classNames.middleImage}>
              <Image
                src={parsedImages[1].url}
                alt={parsedImages[1].alt}
                title={parsedImages[1].title}
                onClick={_ => handleOpen(1)}
                sizes="(max-width: 575px) 46vw, (max-width: 1024px) 39vw, (max-width: 1440px) 24vw, 369px"
                layout="fill"
                objectFit="cover"
                priority
              />
            </Col>
            <Col span={24} sm={8} lg={24} className={classNames.mobileCol}>
              <Row gutter={0} className={classNames.rightRow}>
                {parsedImages.slice(2).map((image, index) => (
                  <Col
                    span={24}
                    key={image.url}
                    sm={24}
                    lg={24}
                    onClick={_ => handleOpen(index + 2)}
                    className={cx(index > 1 && classNames.hidden, classNames.rightImage)}
                  >
                    <Image
                      src={image.url}
                      alt={image.alt}
                      title={image.title}
                      sizes="(max-width: 575px) 46vw, (max-width: 1024px) 19vw, (max-width: 1440px) 24vw, 369px"
                      layout="fill"
                      objectFit="cover"
                      priority={index < 2}
                    />
                    {index === 0 && (
                      <div className={classNames.enlargePlaceholder} >
                        <FontAwesomeIcon icon="search-plus" />
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </Row>
  ), [parsedImages])
  return (
    <Fragment>
      {imageCols}
      {Number.isInteger(isOpenIndex) && (
        <Lightbox
          mainSrc={parsedImages[isOpenIndex].modal}
          nextSrc={parsedImages[(isOpenIndex + 1) % (parsedImages.length)].modal}
          prevSrc={parsedImages[(isOpenIndex + parsedImages.length - 1) % parsedImages.length].modal}
          onCloseRequest={() => handleOpen(null)}
          onMovePrevRequest={() => handleOpen((isOpenIndex + parsedImages.length - 1) % parsedImages.length)}
          onMoveNextRequest={() => handleOpen((isOpenIndex + 1) % parsedImages.length)}
          imageCaption={parsedImages[isOpenIndex].caption}
          imageTitle={<span className={classNames.counter}>{isOpenIndex + 1 + '/' + parsedImages.length}</span>}
          wrapperClassName={classNames.lightWrapper}
        />
      )}
    </Fragment>
  )
}
