import PropTypes from 'prop-types'
import Script from 'next/script'


GoogleAnalytics.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
}

export default function GoogleAnalytics({ data }) {
  if(!data.code) {
    return null
  }
  return (
    <Script id="google-analytics-script">
      {data.code}
    </Script>
  )
}
