import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import get from 'lodash/get'
import Image from 'next/image'

import { Container, CTALink, Typography, BlockWrapper } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import classNames from './styles.module.scss'


DetailCard.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    text: PropTypes.string,
    cta_link_caption: PropTypes.string,
    cta_link: PropTypes.string,
    cta_link_type: PropTypes.string,
    heading: PropTypes.string,
    heading_hx: PropTypes.string,
  }),
  priorityLoad: PropTypes.bool.isRequired,
}

DetailCard.defaultProps = {
  data: {},
}

export default function DetailCard({ data, priorityLoad }) {
  return (
    <BlockWrapper>
      <Container size="xl">
        <Row className={classNames.container}>
          <Col span={24} md={14} className={classNames.imageContainer}>
            <Image
              src={getImgFromBynder(get(data, 'image.bynder', {}), ['webbig', 'webImage'])}
              alt={get(data, 'image.data.alttext')}
              title={get(data, 'image.data.name')}
              layout="fill"
              objectFit="cover"
              priority={priorityLoad}
            />
          </Col>
          <Col span={24} md={10} className={classNames.card}>
            {data.heading && (
              <Typography
                variant="h4"
                tag={data.heading_hx}
                color="black"
                transform="uppercase"
                className={classNames.heading}
              >
                {data.heading}
              </Typography>
            )}
            <Typography
              variant="content"
              color="black"
              tag="p"
              className={classNames.cardText}
            >
              {data.text}
            </Typography>
            <CTALink
              cta={data.cta_link_caption}
              ctaURL={data.cta_link}
              type={data.cta_link_type}
              icon="chevron-right"
              className={classNames.cta}
            />
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
