import { Component } from 'react'
import { compose } from 'redux'
import { connectResources, ResourceType } from '@ds-frontend/resource'
import isEmpty from 'lodash/isEmpty'

import { Loading } from 'common/widgets'


const propTypes = {
  cmsInfo: ResourceType.isRequired,
}

export default function withInfoHOC(ComposedComponent) {
  class WithInfoContainer extends Component {
    constructor(props) {
      super(props)
      if((typeof window === 'undefined') && isEmpty(props.cmsInfo.data)) {
        props.cmsInfo.fetch()
      }
    }

    componentDidMount() {
      if(isEmpty(this.props.cmsInfo.data)) {
        return this.props.cmsInfo.fetch()
      }
    }

    render() {
      return (
        <Loading isLoading={isEmpty(this.props.cmsInfo.data)}>
          <ComposedComponent {...this.props} />
        </Loading>
      )
    }
  }

  WithInfoContainer.propTypes = propTypes

  return compose(
    connectResources({
      endpoint: 'api/v1/cms-info',
      namespace: 'cmsInfo',
      reducer: 'object',
    }),
  )(WithInfoContainer)
}
