import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { Container, MapMultipleMarkers, BlockWrapper } from 'common/widgets'
import marker from '@img/marker.svg'
import markerSelected from '@img/markerSelected.svg'
import classNames from './styles.module.scss'


Map.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        url: PropTypes.string,
      })),
    }),
    PropTypes.arrayOf(PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        url: PropTypes.string,
      })),
    })),
  ]
  ),
}

Map.defaultProps = {
  data: {
    locations: [],
  },
}

const configMap = {
  disableDefaultUI: false,
}

export default function Map({ data }) {
  return (
    <BlockWrapper>
      <Container size="xl">
        <Row gutter={[40, 40]}>
          <Col span={24}>
            <MapMultipleMarkers
              locations={data}
              markerIcon={marker}
              markerIconSelected={markerSelected}
              className={classNames.map}
              configMap={configMap}
              infoWindowContent={`
                <h2>
                  <span class="mapBlockLink"><%= name %><span/>
                </h2>
              `}
            />
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
