import { Container } from 'common/widgets'
import classNames from './styles.module.scss'


export default function DotsList(dots) {
  return (
    <Container size="xl">
      <ul className={classNames.dotsList}>{dots}</ul>
    </Container>
  )
}
