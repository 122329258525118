import { useCallback, useEffect, useRef } from 'react'


export default function withScroll(Component, className) {
  return function withScrollHOC(props) {
    const ref = useRef()

    const handleScroll = useCallback(() => {
      if(ref.current.getBoundingClientRect().top < window.innerHeight / 2) {
        ref.current.classList.add(className)
        window.removeEventListener('scroll', handleScroll)
      }
    }, [ref.current])

    useEffect(() => {
      handleScroll()
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    return <Component {...props} ref={ref} />
  }
}
