import get from 'lodash/get'
import Image from 'next/image'
import PropTypes from 'prop-types'

import { Typography } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


ImageSlide.propTypes = {
  image: ImageType.isRequired,
  priority: PropTypes.bool.isRequired,
}


export default function ImageSlide({ image, priority }) {
  return (
    <div className={classNames.slideWrapper}>
      <div className={classNames.imageWrapper}>
        <Image
          src={getImgFromBynder(get(image, 'bynder'), ['webImage'])}
          alt={get(image, 'data.alttext')}
          title={get(image, 'data.name')}
          sizes="(min-width: 576px) 35vw, 100vw"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority={priority}
        />
      </div>
      <Typography variant="small" className={classNames.caption}>{get(image, 'data.caption')}</Typography>
    </div>
  )
}
