import { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Col, Row } from 'antd'
import Image from 'next/image'

import { Collapse, Container, Panel, Typography, BlockWrapper, CTALink } from 'common/widgets'
import { ImageType } from '../types'
import ExpandIcon from '../Accordion/ExpandIcon'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import classNames from './styles.module.scss'


PortfolioDetailCollapsible.propTypes = {
  data: PropTypes.shape({
    text_blocks: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      cta_link_caption: PropTypes.string,
      cta_link: PropTypes.string,
      cta_link_type: PropTypes.string,
    })),
    image: ImageType,
  }),
}

PortfolioDetailCollapsible.defaultProps = {
  data: {
    text_blocks: [],
  },
}

export default function PortfolioDetailCollapsible({ data }) {
  const items = useMemo(() => data.text_blocks.map(({
    heading,
    heading_hx: headingHx,
    text,
    cta_link_caption: ctaLinkCaption,
    cta_link: ctaLink,
    cta_link_type: ctaLinkType,
  }, ind) => (
    <Panel
      key={heading + ind}
      isClassless
      header={<Typography variant="subhead" tag={headingHx} className={classNames.title}>{heading}</Typography>}
      className={classNames.panel}
    >
      <Typography color="light-black">{text}</Typography>
      {ctaLinkCaption && ctaLink && (
        <CTALink
          cta={ctaLinkCaption}
          ctaURL={ctaLink}
          type={ctaLinkType}
          icon="chevron-right"
          className={classNames.cta}
        />
      )}
    </Panel>
  )), [data.text_blocks])
  return (
    <BlockWrapper>
      <Container size="xl" className={classNames.wrapper} isPadding={false}>
        <Row gutter={[40, 40]} className={classNames.content}>
          <Col span={0} md={2} />
          <Col span={24} md={12}>
            <Collapse accordion expandIcon={ExpandIcon}>
              {items}
            </Collapse>
          </Col>
        </Row>
        <div
          className={classNames.image}
        >
          <Image
            src={getImgFromBynder(get(data, 'image.bynder'), ['webImage', 'webbig'])}
            alt={get(data, 'image.data.alttext')}
            title={get(data, 'image.data.name')}
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      </Container>
    </BlockWrapper>
  )
}
