import { useState, useCallback, useEffect } from 'react'
import NavItem from './NavItem'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'


NavItemWrapper.propTypes = {
  isActiveCart: PropTypes.bool,
}

NavItemWrapper.defaultProps = {
  isActiveCart: false,
}

export default function NavItemWrapper({ isActiveCart, ...props }) {
  const [ activeCards, setActiveCards ] = useState({})
  const handleActiveCard = useCallback(id => {
    setActiveCards({ ...activeCards, [id]: !activeCards[id] })
  }, [activeCards, setActiveCards])
  useEffect(() => {
    if(!isActiveCart) {
      setActiveCards({})
    }
  }, [isActiveCart])
  return (
    <div className={classNames.navWrapper}>
      <NavItem
        activeCards={activeCards}
        setActiveCards={setActiveCards}
        isActiveCart={isActiveCart}
        handleActiveCard={handleActiveCard}
        isFirstLevel
        {...props}
      />
    </div>
  )
}
