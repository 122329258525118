import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


Branches.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    phone_number: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    address_city: PropTypes.string,
    address_postal_code: PropTypes.string,
  })),
}

Branches.defaultProps = {
  branches: [],
}

export default function Branches({ branches }) {
  const branchItems = useMemo(() => (branches || []).map(({ name, address_line_1: firstAddress, address_line_2: secondAddress, address_city: city, address_postal_code: postalCode, phone_number: phoneNumber, email }) => (
    <Col key={firstAddress} span={24} sm={12} lg={6} className={classNames.column}>
      <Typography variant="h4" className={classNames.city}>{name}</Typography>
      <Typography variant="content" color="light-black" className={classNames.text}>
        {firstAddress}
        {firstAddress && secondAddress && ', '}
        {secondAddress}
      </Typography>
      <Typography variant="content" color="light-black" className={classNames.text}>
        {postalCode}
        {postalCode && city && ', '}
        {city}
      </Typography>
      {phoneNumber && (
        <Typography
          tag="a"
          href={`tel:${phoneNumber}`}
          variant="content"
          color="light-black"
          className={classNames.text}
        >
          {phoneNumber}
        </Typography>
      )}
      {email && (
        <Typography
          tag="a"
          href={`mailto:${email}`}
          variant="content"
          color="light-black"
          className={classNames.text}
        >
          {email}
        </Typography>
      )}
    </Col>
  )), [branches])

  return <Row gutter={[32, 32]}>{branchItems}</Row>
}
