import { connectResources } from '@ds-frontend/resource'

import ExperienceMasonryView from './ExperienceMasonryView'


export default connectResources({
  endpoint: 'api/v1/feefo/experiences',
  namespace: 'getExperiences',
  reducer: 'paginationList',
  queries: ['kind', 'parent_id', 'offset', 'limit'],
}, { prefetch: false })(ExperienceMasonryView)
