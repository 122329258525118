import PropTypes from 'prop-types'
import Image from 'next/image'

import { Container, Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'


SinglePageHeroView.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default function SinglePageHeroView({ data }) {
  const { gettext } = useTranslations()
  return (
    <div className={classNames.schoolHero}>
      {
        data.image && (
          <Image
            src={data.image}
            alt={data.subtitle}
            title={data.title}
            layout="fill"
            objectFit="cover"
            priority
          />
        )
      }
      <Container className={classNames.heroContainer} size="xl">
        {(data.title || data.subtitle) && (
          <Typography variant="h1" className={classNames.heroTitle}>
            {data.title + ' '}
            <div className={classNames.schoolGroup}>
              {interpolate(gettext('Sprachschule in %s'), [data.subtitle])}
            </div>
          </Typography>
        )}
      </Container>
    </div>
  )
}
