import { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Image from 'next/image'

import { Typography } from 'common/widgets'
import CustomTypography from '../CustomTypography'
import classNames from './styles.module.scss'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import ImageType from '../../../types/ImageType'


DetailModal.propTypes = {
  person: PropTypes.shape({
    image: ImageType,
    name: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  }),
}

DetailModal.defaultProps = {
  person: {},
}

export default function DetailModal({ person }) {
  const leftStyles = useMemo(() => ({ backgroundImage: isEmpty(person) ? 'none' : `url(${get(person, 'image.bynder.derivatives.webImage')})` }), [person.image])
  return (
    <div className={cx(classNames.detail, !isEmpty(person) && classNames.active)}>
      <div className={classNames.detailInner}>
        <div className={classNames.detailLeft} style={leftStyles}>
          <div className={classNames.detailPhoto}>
            <div>
              {get(person, 'image.bynder.derivatives.webImage', '') && (
                <Image
                  src={getImgFromBynder(get(person, 'image.bynder'), ['webImage'])}
                  alt={get(person, 'image.data.alttext')}
                  title={get(person, 'image.data.name')}
                  layout="fill"
                  objectFit="cover"
                  priority
                />
              )}
            </div>
          </div>
        </div>
        <div className={classNames.detailRight}>
          <div className={classNames.detailHeader}>
            <CustomTypography variant="name">{person.name}</CustomTypography>
            <CustomTypography variant="title">{person.title}</CustomTypography>
          </div>
          <Typography
            variant="content"
            color="black"
            tag="div"
            dangerouslySetInnerHTML={{ __html: person.text || '' }}
          />
        </div>
      </div>
    </div>
  )
}
