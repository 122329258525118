import { useEffect, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Container, Typography, BlockWrapper } from 'common/widgets'
import Score from './widgets/Score'
import Video from './widgets/Video'
import classNames from './styles.module.scss'


CardWithVideo.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    review_score: PropTypes.number,
    subheading: PropTypes.string,
    text: PropTypes.string,
    video_url: PropTypes.string,
    video_poster_url: PropTypes.string,
  }),
}

CardWithVideo.defaultProps = {
  data: {},
}

export default function CardWithVideo({ data }) {
  const [isVisible, setIsVisible] = useState(false)
  const container = useRef()
  const handleScroll = useCallback(() => {
    if(
      container.current.getBoundingClientRect().top
      < window.innerHeight / 2 && data?.review_score
    ) {
      setIsVisible(true)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setIsVisible])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <BlockWrapper>
      <Container size="xl">
        <div className={classNames.row} ref={container}>
          <div className={classNames.info}>
            <div>
              {data.heading && (
                <Typography variant="h2">
                  {data.heading}
                </Typography>
              )}
              {data.subheading && (
                <Typography
                  variant="h4"
                  tag="h3"
                  color="light-black"
                  className={classNames.subheading}
                >
                  {data.subheading}
                </Typography>
              )}
              <Typography
                variant="content"
                color="light-black"
                className={classNames.text}
              >
                {data.text}
              </Typography>
            </div>
            {data?.review_score && <Score isVisible={isVisible} score={data.review_score} />}
          </div>
          <div>
            <Video video={data.video_url} videoImage={data.video_poster_url} />
          </div>
        </div>
      </Container>
    </BlockWrapper>
  )
}
