import { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import cx from 'classnames'
import get from 'lodash/get'
import Image from 'next/image'

import { Container, CTALink, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'
import { ImageType } from '../types'


ImageGridSquare.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      tag: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      cta_link: PropTypes.string,
    })),
    cta_link_caption: PropTypes.string,
    cta_link_type: PropTypes.string,
  }),
}

ImageGridSquare.defaultProps = {
  data: {
    columns: [],
  },
}

export default function ImageGridSquare({ data }) {
  const secondOption = useRef()
  const onMouseEnter = useCallback(event => {
    secondOption.current.classList.remove(classNames.hover)
    event.target.classList.add(classNames.hover)
  }, [])
  const onMouseLeave = useCallback(event => {
    event.target.classList.remove(classNames.hover)
    secondOption.current.classList.add(classNames.hover)
  }, [])
  const options = useMemo(() => data.columns.map(({ image, tag, heading, text, cta_link: ctaLink }, index) => (
    <Col key={index} span={24} md={12} xl={8}>
      <div
        ref={index === 1 ? secondOption : null}
        className={cx(classNames.container, index === 1 && classNames.hover)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Image
          src={get(image, 'bynder.derivatives.webImage', '')}
          alt={get(image, 'data.alttext')}
          title={get(image, 'data.name')}
          layout="fill"
          objectFit="cover"
          priority
        />
        <div className={classNames.inner}>
          <div className={classNames.innerInfo}>
            {tag && <Typography color="light-black" className={classNames.tag}>{tag}</Typography>}
            <Typography variant="h2" className={classNames.title}>{heading}</Typography>
            <Typography color="light-black" className={classNames.text}>{text}</Typography>
          </div>
          <div className={classNames.borderTop} />
          <div className={classNames.borderRight} />
          <div className={classNames.borderBottom} />
          <div className={classNames.borderLeft} />
          <CTALink
            cta={data.cta_link_caption}
            type={data.cta_link_type}
            ctaURL={ctaLink}
            isUnderline
            className={classNames.cta}
          />
        </div>
      </div>
    </Col>
  )), [data.columns])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Row gutter={[30, 30]}>{options}</Row>
      </Container>
    </BlockWrapper>
  )
}
