import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import classNames from './styles.module.scss'


Arrow.propTypes = {
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

Arrow.defaultProps = {
  className: undefined,
  style: undefined,
  onClick: undefined,
}

export default function Arrow({ position, className, style, onClick }) {
  return (
    <FontAwesomeIcon
      icon={`chevron-${position}`}
      className={cx(className, classNames.arrow, classNames[`arrow-${position}`])}
      style={style}
      onClick={onClick}
    />
  )
}
