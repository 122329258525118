import { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Container, Typography, BlockWrapper } from 'common/widgets'
import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


HiddenCollapsibleText.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    visible_text: PropTypes.string,
    hidden_text: PropTypes.string,
  }),
}

HiddenCollapsibleText.defaultProps = {
  data: {},
}

export default function HiddenCollapsibleText({ data }) {
  const [open, setOpen] = useState(false)
  const hiddenText = useRef()
  const { gettext } = useTranslations()
  const btnText = useMemo(() => gettext(open ? 'Read less' : 'Read more'), [open])

  const handleOpen = useCallback(() => {
    setOpen(!open)
    hiddenText.current.style.height = 0
    hiddenText.current.style.minHeight = open ? 0 : `${hiddenText.current.scrollHeight}px`
  }, [open, setOpen, hiddenText.current])

  const handleTransitionEnd = useCallback(() => {
    hiddenText.current.style.height = open ? 'auto' : null
  }, [open, hiddenText.current])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Typography variant="h3" tag="h2" className={classNames.heading}>{data.heading}</Typography>
        <Typography className={classNames.text}>{data.visible_text}</Typography>
        <div
          ref={hiddenText}
          className={classNames.collapse}
          onTransitionEnd={handleTransitionEnd}
        >
          <Typography className={classNames.text}>{data.hidden_text}</Typography>
        </div>
        <Button
          type="primary-outlined"
          className={classNames.button}
          onClick={handleOpen}
        >
          <Typography color="teal">
            {btnText}
          </Typography>
        </Button>
      </Container>
    </BlockWrapper>
  )
}
