import PropTypes from 'prop-types'
import get from 'lodash/get'

import { Container, HotLeisureTips, BlockWrapper } from 'common/widgets'
import { ImageType } from '../types'


AccordionWithImages.propTypes = {
  data: PropTypes.shape({
    leisure_tips: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      images: PropTypes.PropTypes.arrayOf(ImageType),
    })),
  }),
}

AccordionWithImages.defaultProps = {
  data: {
    leisure_tips: [],
  },
}

export default function AccordionWithImages({ data }) {
  return (
    <BlockWrapper>
      <Container size="xl">
        <HotLeisureTips data={get(data, 'leisure_tips', [])} />
      </Container>
    </BlockWrapper>
  )
}
