import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslations } from 'common/language'

import classNames from './styles.module.scss'


ScoreView.propTypes = {
  score: PropTypes.number,
  isVisible: PropTypes.bool,
}

ScoreView.defaultProps = {
  isVisible: false,
  score: 98,
}

export default function ScoreView({ isVisible, score }) {
  const [ count, setCount ] = useState(0)
  useEffect(() => {
    if(isVisible && count < score) {
      setTimeout(_ => setCount(count + 1), 10)
    }
  }, [count, setCount, score, isVisible])
  const { gettext } = useTranslations()
  return (
    <div className={classNames.scoreContainer}>
      <div className={classNames.scoreBorder} />
      <div className={classNames.scoreContent}>
        <div className={classNames.score}>
          {isVisible && (count + '%')}
        </div>
        <div className={classNames.scoreText}>{gettext('of Happy \n Clients')}</div>
      </div>
    </div>
  )
}
