import { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Image from 'next/image'

import getImgFromBynder from 'common/utils/getImgFromBynder'
import { CTALink, Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


Slide.propTypes = {
  image: ImageType,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  text: PropTypes.string,
  wasSwiped: PropTypes.bool,
  cta_link: PropTypes.string.isRequired,
  cta_link_caption: PropTypes.string.isRequired,
  priority: PropTypes.bool,
}

Slide.defaultProps = {
  subheading: undefined,
  text: undefined,
  wasSwiped: false,
  image: {},
  priority: false,
}

let touchStart

export default function Slide({ image, heading, subheading, text, cta_link: ctaLink, cta_link_caption: caption, wasSwiped, priority }) {
  const slide = useRef()
  const handleTouch = useCallback((event) => {
    touchStart = event.timeStamp
  }, [slide.current])

  const handleTouchEnd = useCallback((event) => {
    if(event.timeStamp - touchStart <= 500 && !event.target.closest(`div.${classNames.textContainer}, a[href='${ctaLink}']`)) {
      event.preventDefault()
      slide.current.classList.toggle(classNames.touched)
    }
  }, [slide.current])

  const handleMove = useCallback((event) => {
    if(event.target.closest(`.${classNames.textContainer}`)) {
      if(event.type === 'mousemove' && event.buttons === 1 || event.type === 'touchmove') {
        event.stopPropagation()
      }
    }
  }, [slide.current])

  useEffect(() => {
    if(wasSwiped) {
      slide.current.classList.remove(classNames.touched)
    }
    return () => {
      touchStart = undefined
    }
  }, [wasSwiped])
  const imgSrc = useMemo(() => getImgFromBynder(get(image, 'bynder'), ['webImage']), [image])

  return (
    <div ref={slide} className={classNames.slide} onTouchStart={handleTouch} onTouchEnd={handleTouchEnd} onMouseMove={handleMove} onTouchMove={handleMove}>
      <Image
        src={imgSrc}
        alt={get(image, 'data.alttext')}
        title={get(image, 'data.name')}
        layout="fill"
        objectFit="cover"
        priority={priority}
        sizes="(max-width: 767px) 100vw, 50vw"
      />
      <div className={classNames.info}>
        <div>
          <Typography variant="h3" color="white" className={classNames.hoverHeading}>
            {heading}
          </Typography>
          <Typography variant="h3" color="white" className={classNames.heading}>
            {heading}
          </Typography>
          <div className={classNames.textContainer}>
            {subheading && (
              <Typography variant="h4" color="white" className={classNames.subheading}>{subheading}</Typography>
            )}
            <Typography
              variant="content"
              color="white"
              dangerouslySetInnerHTML={{ __html: text }}
              tag="div"
            />
          </div>
        </div>
        <CTALink cta={caption} ctaURL={ctaLink} icon="chevron-right" />
      </div>
    </div>
  )
}
