import { withFinalForm } from '@ds-frontend/resource'
import { compose } from 'redux'
import { notification } from 'antd'
import pick from 'lodash/pick'

import FormsContainer from './FormsContainer'
import { withTranslations } from 'common/language'


export default compose(
  withTranslations,
  withFinalForm({
    onSubmit: (data, form, props) => {
      return props.crmForms.create({
        kind: props.data.form_kind,
        ...pick(data, form.getRegisteredFields()),
      })
        .then(resp => {
          if(props.data.redirect) {
            const url = props.data.redirect.replace(/\/de\/|\/fr\//g, '/')
            window.location.href = `/${props.languageCode}${url}`
          } else {
            notification.success({
              message: props.gettext('Success'),
              description: props.gettext('Form sent successfully'),
              duration: 5,
            })
          }
          return resp
        })
    },
  },
  {
    endpoint: 'api/v1/crm/forms/:kind',
    namespace: 'crmForms',
  },
  {
    prefetch: false,
  }),
)(FormsContainer)
