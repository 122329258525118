import { useMemo } from 'react'
import PropTypes from 'prop-types'
import RMasonry from 'react-masonry-css'

import { Container, BlockWrapper } from 'common/widgets'
import MasonryItem from './MasonryItem'
import classNames from './styles.module.scss'


Masonry.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        tag: PropTypes.string,
        date: PropTypes.string,
        heading: PropTypes.string,
        text: PropTypes.string,
        cta_url: PropTypes.string,
      })
    ),
    cta_link_caption: PropTypes.string,
  }),
  headingTag: PropTypes.string,
}

Masonry.defaultProps = {
  data: {
    columns: [],
  },
  headingTag: undefined,
}

export default function Masonry({ data, headingTag }) {
  const items = useMemo(
    () =>
      data.columns.map((option, index) => (
        <MasonryItem
          key={index}
          cta={data.cta_link_caption}
          headingTag={headingTag}
          {...option}
        />
      )),
    [data.columns]
  )
  const breakpointColumnsObj = useMemo(
    () => ({
      default: 3,
      1024: 2,
      576: 1,
    }),
    []
  )

  return (
    <BlockWrapper>
      <Container size="xl">
        <RMasonry
          breakpointCols={breakpointColumnsObj}
          className={classNames.container}
          columnClassName={classNames.column}
        >
          {items}
        </RMasonry>
      </Container>
    </BlockWrapper>
  )
}
