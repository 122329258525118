export default function generateDataForChart(data, otherLabel) {
  if(!data.length) {
    return []
  }
  return data.sort((a, b) => b.percent - a.percent).reduce((arr, el, ind) => {
    if(ind + 1 === data.length) {
      const otherPercentCount = 100 - arr.reduce((sum, el) => sum + el[1], 0) - el.percent
      const other = [otherLabel, otherPercentCount]
      return [...arr, [el.country_name, el.percent], other]
    }
    return [...arr, [el.country_name, el.percent]]
  }, [])
}
