import PropTypes from 'prop-types'
import { Col, Row, Space } from 'antd'
import omit from 'lodash/omit'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'

import { Container, Typography } from 'common/widgets'
import Forms from '../Forms/Forms'
import Map from './widgets/Map'
import Branches from './widgets/Branches'
import classNames from './styles.module.scss'


ContactView.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    branches: PropTypes.array,
    form: PropTypes.shape({
      form_kind: PropTypes.string,
      redirect: PropTypes.string,
    }),
  }),
}

ContactView.defaultProps = {
  data: {},
}

export default function ContactView({ data }) {
  const branchesList = data.branches
    .map(i => ({ ...omit(i, 'location'), ...i.location }))
    .filter(i => isNumber(i.lat) && isNumber(i.lng))
  const isMapExist = data.branches.some(i => !isEmpty(i.location))
  return (
    <div className={classNames.wrapper}>
      <Container size="xl">
        <Space direction="vertical" size="large" className={classNames.fullWidth}>
          <Row gutter={32}>
            <Col span={24} md={isMapExist ? 12 : 24}>
              <Typography variant="h2" className={classNames.heading}>{data.title}</Typography>
              <Typography variant="h3" className={classNames.subheading}>{data.subtitle}</Typography>
              <Typography variant="content" color="light-black">{data.description}</Typography>
              <Forms data={data.form} withoutPadding />
            </Col>
            {isMapExist && (
              <Col span={24} md={12}>
                <Map branches={branchesList} />
              </Col>
            )}
          </Row>
          <Branches branches={branchesList} />
        </Space>
      </Container>
    </div>
  )
}
