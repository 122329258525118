import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import get from 'lodash/get'
import Image from 'next/image'

import { Container, CTALink, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'
import { ImageType } from '../types'
import getImgFromBynder from 'common/utils/getImgFromBynder'


ImageGrid.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      heading: PropTypes.string,
      hover_text: PropTypes.string,
      cta_url: PropTypes.string,
      cta_link_type: PropTypes.string,
      cta_link_caption: PropTypes.string,
    })),
  }),
}

ImageGrid.defaultProps = {
  data: {},
}

export default function ImageGrid({ data }) {
  const { gettext } = useTranslations()
  const images = useMemo(() => data.items.map(({
    image,
    heading,
    hover_text: hoverText,
    cta_link: ctaLink,
    cta_link_caption: ctaCaption,
    cta_link_type: ctaType,
    image_size: imageSize,
  }, idx) => (
    <Col
      key={idx + heading}
      span={24}
      md={12}
      lg={imageSize === 'wide' ? 16 : 8}
      className={classNames.column}
    >
      <div className={classNames.imageContainer}>
        <div className={classNames.image}>
          <Image
            src={getImgFromBynder(get(image, 'bynder'), ['webImage'])}
            alt={get(image, 'data.alttext', '')}
            title={get(image, 'data.name', '')}
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className={classNames.back}>
          <div>
            <Typography variant="h3" color="white">{heading}</Typography>
            <Typography varinat="content" color="white" className={classNames.text}>{hoverText}</Typography>
          </div>
          {ctaLink && (
            <CTALink
              cta={ctaCaption || gettext('Read more')}
              ctaURL={ctaLink}
              icon="chevron-right"
              type={ctaType}
            />
          )}
        </div>
      </div>
    </Col>
  )), [data.items])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Row>{images}</Row>
      </Container>
    </BlockWrapper>
  )
}
