import PropTypes from 'prop-types'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations } from 'common/language'


SinglePageHighlightsView.propTypes = {
  data: PropTypes.array.isRequired,
}

export default function SinglePageHighlightsView({ data }) {
  const { gettext } = useTranslations()
  return (
    <div className={classNames.HighlightRoot}>
      <Typography
        variant="block-title"
        transform="uppercase"
      >
        {gettext('Highlights')}
      </Typography>
      <ul className={classNames.HighlightList}>
        {data.map((highlight, index) => (
          <li
            key={index}
            className={classNames.HighlightItem}
          >
            <Typography variant="subhead" className={classNames.highlightText}>
              {highlight.name}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  )
}
