import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { Container, BlockWrapper } from 'common/widgets'
import usePrevious from 'common/utils/usePrevious'
import Arrow from './Arrow'
import Slide from './Slide'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


PortfolioCarousel.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      heading: PropTypes.string,
      subheading: PropTypes.string,
      text: PropTypes.string,
      cta: PropTypes.string,
      cta_link: PropTypes.string,
    })),
  }),
  priorityLoad: PropTypes.bool,
}

PortfolioCarousel.defaultProps = {
  data: {
    items: [],
  },
  priorityLoad: false,
}

export default function PortfolioCarousel({ data, priorityLoad }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const prevIndex = usePrevious(currentIndex)
  const settings = useMemo(() => ({
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <Arrow position="right" />,
    prevArrow: <Arrow position="left" />,
    afterChange: setCurrentIndex,
  }), [])
  const slides = useMemo(() => data.items.map((option, index) => (
    <Slide
      key={option.heading}
      {...option}
      wasSwiped={index === prevIndex && prevIndex !== currentIndex}
      priority={priorityLoad && index < 2}
    />
  )), [data.items, prevIndex, currentIndex])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Slider {...settings} className={classNames.slider}>
          {slides}
        </Slider>
      </Container>
    </BlockWrapper>
  )
}
