import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'


AmenityCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default function AmenityCard({ icon, title }) {
  return (
    <div className={classNames.AmenityCard}>
      <div>
        <FontAwesomeIcon icon={icon} />
      </div>
      <p>
        {title}
      </p>
    </div>
  )
}
