import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import { Container, Typography, BlockWrapper } from 'common/widgets'
import classNames from './styles.module.scss'


AnimatedLink.propTypes = {
  data: PropTypes.shape({
    left_side_text: PropTypes.string,
    right_side_text: PropTypes.string,
  }).isRequired,
}

AnimatedLink.defaultProps = {
  data: {},
}

export default function AnimatedLink({ data }) {
  const [ link, setLink ] = useState({})
  useEffect(() => {
    const content = document.createElement('div')
    content.innerHTML = data.left_side_text
    Array.prototype.forEach.call(
      content.querySelectorAll('a'), (link) => {
        link.className = classNames.link
        link.innerHTML = link.innerHTML
          .split('')
          .map((symbol, index) =>
            `<span style="transition-delay: ${index / 10}s">${symbol}</span>`
          )
          .join('')
      }
    )
    setLink(content)
  }, [])
  return (
    <BlockWrapper>
      <Container size="xl">
        <Row>
          <Col span={24} md={data.right_side_text ? 12 : 24}>
            <div
              className={classNames.wrapper}
              dangerouslySetInnerHTML={{
                __html: link.innerHTML,
              }}
            />
          </Col>
          <Col span={24} md={12} className={classNames.right}>
            <Typography>{data.right_side_text}</Typography>
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
