import PropTypes from 'prop-types'

import { MapMultipleMarkers } from 'common/widgets'
import marker from '@img/marker.svg'
import markerSelected from '@img/markerSelected.svg'
import classNames from './styles.module.scss'


Map.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
  })),
}

Map.defaultProps = {
  branches: [],
}

export default function Map({ branches }) {
  return (
    <MapMultipleMarkers
      locations={branches}
      className={classNames.map}
      infoWindowContent={'<h2><%= name %></h2>'}
      markerIcon={marker}
      markerIconSelected={markerSelected}
    />
  )
}
