import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


ExpandIcon.propTypes = {
  isActive: PropTypes.bool,
}

ExpandIcon.defaultProps = {
  isActive: false,
}

export default function ExpandIcon({ isActive }) {
  return <FontAwesomeIcon icon={isActive ? 'minus' : 'plus'} />
}
