import Slider from 'react-slick'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Container } from 'common/widgets'
import AmenityCard from './AmenityCard'
import Dots from './Dots'
import DotsList from './DotsList'
import classNames from './styles.module.scss'


const SLIDES_TO_SHOW = 6
const MOBILE_SLIDES_TO_SHOW = 4

Amenity.propTypes = {
  data: PropTypes.array.isRequired,
}

export default function Amenity({ data }) {
  const settings = useMemo(_ => ({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(data.length, SLIDES_TO_SHOW),
    slidesToScroll: Math.min(data.length, SLIDES_TO_SHOW),
    appendDots: DotsList,
    customPaging: Dots,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(data.length, MOBILE_SLIDES_TO_SHOW),
          slidesToScroll: Math.min(data.length, MOBILE_SLIDES_TO_SHOW),
        },
      },
    ],
  }), [data])
  const amenity = useMemo(() => data.map(amenity => (
    <AmenityCard icon={amenity.icon} title={amenity.name} key={amenity.icon} />
  )), [data])
  return (
    <Container
      size="xl"
      className={classNames.AmenityContainer}
      isPadding={false}
    >
      <div className={classNames.screenView}>
        <Slider {...settings}>
          {amenity}
        </Slider>
      </div>
      <div className={classNames.printView}>
        {amenity.slice(0, 6)}
      </div>
    </Container>
  )
}
