import cx from 'classnames'
import PropTypes from 'prop-types'

import classNames from './styles.module.scss'


Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleStatus: PropTypes.func.isRequired,
}

export default function Hamburger({ isActive, toggleStatus }) {
  return (
    <button
      className={cx(classNames.hamburger, isActive && cx(classNames.active, 'active'))}
      onClick={toggleStatus}
      aria-label="Sidebar Menu"
    >
      <span />
      <span />
      <span />
    </button>
  )
}
