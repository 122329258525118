import { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import cx from 'classnames'

import { Container, Typography, BlockWrapper } from 'common/widgets'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


TestimonialImages.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      name: PropTypes.string,
      subheading: PropTypes.string,
      text: PropTypes.string,
    })),
  }),
}

TestimonialImages.defaultProps = {
  data: {
    items: [],
  },
}

export default function TestimonialImages({ data }) {
  const secondOption = useRef()
  const onMouseEnter = useCallback(event => {
    secondOption.current.classList.remove(classNames.hover)
    event.target.classList.add(classNames.hover)
  }, [])
  const onMouseLeave = useCallback(event => {
    event.target.classList.remove(classNames.hover)
    secondOption.current.classList.add(classNames.hover)
  }, [])

  const items = useMemo(() => data.items.map(({ image, name, subheading, text }, index) => (
    <div
      key={index}
      ref={index === 1 ? secondOption : null}
      className={cx(classNames.item, index === 1 && classNames.hover)}
      style={{ zIndex: data.items.length - index }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img
        className={classNames.image}
        src={get(image, 'bynder.derivatives.webImage')}
        alt={get(image, 'data.alttext')}
        title={get(image, 'data.name')}
      />
      <div className={classNames.content}>
        <Typography variant="h3" color="white">{name}</Typography>
        <Typography variant="content" color="light-gray">{subheading}</Typography>
        <div className={classNames.separator} />
        <Typography variant="content" className={classNames.quote}>{text}</Typography>
      </div>
    </div>
  )), [data.items])

  return (
    <BlockWrapper>
      <Container size="xl" className={classNames.container}>
        {items}
      </Container>
    </BlockWrapper>
  )
}
