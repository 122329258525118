import { useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { CollapseText, Container, Typography, BlockWrapper } from 'common/widgets'
import ImageGrid from './widgets/ImageGrid'
import classNames from './styles.module.scss'
import { ImageType } from '../types'


AlbumGalleryCard.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    heading_hx: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.arrayOf(ImageType),
  }),
}

AlbumGalleryCard.defaultProps = {
  data: {},
}

export default function AlbumGalleryCard({ data }) {
  const text = useMemo(() => data.text ? data.text.split('\n').map((paragraph, index) => (
    <Typography
      key={index}
      variant="content"
      color="light-black"
      className={classNames.text}
    >
      {paragraph}
    </Typography>
  )) : [], [data.text])

  return (
    <BlockWrapper>
      <Container size="xl">
        <div className={classNames.descriptionRow}>
          <div>
            <Typography variant="h2" tag={data.heading_hx} className={classNames.desktopHeading}>{data.heading}</Typography>
            <div className={classNames.desktopText}>{text}</div>
            <CollapseText isCollapsedDefault initialHeight={120} className={classNames.mobileText}>
              {text}
            </CollapseText>
          </div>
          {!isEmpty(data.images) && (
            <div>
              <ImageGrid images={data.images} />
            </div>
          )}
        </div>
      </Container>
    </BlockWrapper>
  )
}
