import PropTypes from 'prop-types'
import cx from 'classnames'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


Name.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Name.defaultProps = {
  className: undefined,
  children: undefined,
}

export default function Name({ className, children }) {
  return (
    <Typography
      variant="block-title"
      tag="p"
      color="black"
      className={cx(classNames.name, className)}
    >
      {children}
    </Typography>
  )
}
