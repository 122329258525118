import { connect } from 'react-redux'
import get from 'lodash/get'
import template from 'lodash/template'

import HeroImage from './HeroImage'
import parseTemplateData from 'common/utils/parseTemplateData'


export default connect((state, { pageType, seoContext, data, languageCode }) => {
  const seoTemplates = get(state, 'cmsInfo.data.seo_templates', {})[pageType] || {}
  const titleTemplate = seoTemplates[`h1_title_${languageCode}`]
  const pretitleTemplate = seoTemplates[`h1_pretitle_${languageCode}`]
  const subtitleTemplate = seoTemplates[`h1_subtitle_${languageCode}`]
  return {
    data: {
      ...data,
      title: template(parseTemplateData(titleTemplate, seoContext))(seoContext),
      subtitle: template(parseTemplateData(subtitleTemplate, seoContext))(seoContext),
      pretitle: template(parseTemplateData(pretitleTemplate, seoContext))(seoContext),
      pretitle_font_size: seoTemplates.h1_pretitle_font_size,
      title_font_size: seoTemplates.h1_title_font_size,
      subtitle_font_size: seoTemplates.h1_subtitle_font_size,
    },
  }
})(HeroImage)
