import { useState, useCallback } from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Loading } from 'common/widgets'
import classNames from './styles.module.scss'


VideoContainer.propTypes = {
  video: PropTypes.string,
  videoImage: PropTypes.string,
  type: PropTypes.string,
}

VideoContainer.defaultProps = {
  video: undefined,
  videoImage: undefined,
  type: 'video/mp4',
}

const Video = dynamic(() => import('./Video'), { ssr: false,
  loading: () => <Loading isLoading fontSize={48} /> })

export default function VideoContainer({ video: src, type, videoImage }) {
  const [isPlayerEnabled, setIsPlayerEnabled] = useState(false)
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleClick = useCallback(() => {
    setIsPlayerEnabled(isPlayerEnabled => {
      if(!isPlayerEnabled) {
        return true
      }
      if(isPlayerEnabled && player) {
        if(player.paused() && isPlaying === false) {
          player.play()
        }
      }
      return isPlayerEnabled
    })
  }, [player, isPlaying])

  return (
    <div className={cx(classNames.videoBtn, isPlaying && classNames.open)} onClick={handleClick}>
      <div className={classNames.videoContainer}>
        {isPlayerEnabled
          ? (<Video src={src} type={type} videoImage={videoImage} setIsPlaying={setIsPlaying} setPlayer={setPlayer} />)
          : (
            <Image
              src={videoImage}
              layout="fill"
              sizes="(max-width: 767px) 100vw,
                     50vw"
              objectFit="cover"
              objectPosition="46% 50%"
              quality={85}
              className={classNames.imagePoster}
              alt="video poster image"
            />)}
        <div className={classNames.apertureLeft} />
        <div className={classNames.apertureTopRight} />
        <div className={classNames.apertureBottomRight} />
      </div>
    </div>
  )
}
